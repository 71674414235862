/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { Box } from "@theme-ui/components"

const Container = ({ children, fullWidth, ...restProps }) => {
  const containerStyle = {
    maxWidth: [
      theme => 540 + theme.spacing.horizontal * 2,
      theme => 720 + theme.spacing.horizontal * 2,
      theme => 1300 + theme.spacing.horizontal * 2,
    ],
    minWidth: 0,
    mx: "auto",
    px: theme => theme.spacing.horizontal,
    py: theme => theme.spacing.vertical.sm,
    width: "100%",
  }

  if (fullWidth) {
    fullWidth.forEach((fullWidthValue, index) => {
      if (fullWidthValue) {
        containerStyle.maxWidth[index] = "100%"
        containerStyle.px[index] = 0
      }
    })
  }

  return (
    <Box
      sx={{
        ...containerStyle,
      }}
      {...restProps}
    >
      {children}
    </Box>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.arrayOf(PropTypes.bool),
}

export default Container
