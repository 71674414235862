/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Modal from "./modal"
import Accordion from "./accordion"
import Container from "./container"
import { useStaticQuery, graphql } from "gatsby"
import ContentCard from "./content-card"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        navbarItems {
          ...ContentCardData
        }
      }
    }
  `)
  const [showMenu, setShowMenu] = React.useState(false)
  const renderDesktopHeader = () => (
    <Container>
      {data.contentfulSite.navbarItems.map((card, index) => (
        <ContentCard key={index} card={card} sx={{ mr: [null, null, 4] }} />
      ))}
    </Container>
  )
  const renderMobileHeader = () => (
    <Container
      sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}
    >
      <Modal visible={showMenu} onClose={() => setShowMenu(false)}>
        <Accordion
          items={data.contentfulSite.navbarItems.map(card => ({
            title: card.title,
            link: card.url,
          }))}
        />
      </Modal>
      <button
        sx={{ width: "30px", cursor: "pointer" }}
        onClick={() => setShowMenu(true)}
      >
        <div
          sx={{
            backgroundColor: "text",
            height: 3,
            mb: 1,
            width: 25,
          }}
        />
        <div
          sx={{
            backgroundColor: "text",
            height: 3,
            mb: 1,
            width: 25,
          }}
        />
        <div
          sx={{
            backgroundColor: "text",
            height: 3,
            width: 25,
          }}
        />
      </button>
    </Container>
  )
  return (
    <header>
      <div sx={{ display: ["block", "block", "none"] }}>
        {renderMobileHeader()}
      </div>
      <div sx={{ display: ["none", "none", "block"] }}>
        {renderDesktopHeader()}
      </div>
    </header>
  )
}

export default Header
