/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Link from "./link"

const CTA = ({ link, classes, ...restProps }) => {
  const style = React.useMemo(() => {
    const styles = {
      default: {
        display: "inline-block",
        border: theme => `1px solid ${theme.colors.primary}`,
        borderRadius: "5px",
        ":hover": {
          border: theme => `1px solid ${theme.colors.secondary}`,
        },
        p: 3,
      },
      "navbar-link": {
        display: "inline-block",
        border: 0,
        borderRadius: 0,
        ":hover": {
          border: 0,
        },
        p: 2,
      },
    }
    let finalStyle = { ...styles.default }
    if (classes) {
      classes.forEach(className => {
        finalStyle = { ...finalStyle, ...styles[className] }
      })
    }
    return finalStyle
  }, [classes])
  return <Link link={link} sx={style} {...restProps} />
}

export default CTA
