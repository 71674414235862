/** @jsx jsx */
import { jsx, Flex } from "theme-ui"
import PropTypes from "prop-types"
import {
  Accordion as RAAcordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"

import Text from "./text"
import chevron from "../images/icon-arrow.svg"
import Link from "./link"

const Accordion = ({ items }) => {
  return (
    <RAAcordion allowZeroExpanded={true}>
      {items &&
        items.map((item, idx) =>
          item.link ? (
            <Link
              link={item.link}
              sx={{
                borderTop: "1px solid #ccc",
                display: "block",
                lineHeight: "40px",
                py: "15px",
                ":last-child": {
                  borderBottom: "1px solid #ccc",
                },
              }}
            >
              <Text
                type="h3"
                sx={{ pl: 30, display: "block", color: "inherit" }}
              >
                {item.title}
              </Text>
            </Link>
          ) : (
            <AccordionItem
              sx={{
                borderTop: "1px solid #ccc",
                ":last-child": {
                  borderBottom: "1px solid #ccc",
                },
                ":focus, & *:focus": { outline: 0 },
              }}
              key={idx}
            >
              <AccordionItemHeading>
                <AccordionItemButton
                  sx={{
                    py: "15px",
                    cursor: "pointer",
                  }}
                >
                  <Flex
                    sx={{
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <AccordionItemState>
                      {state => (
                        <span
                          sx={{
                            position: "absolute",
                            top: "-4px",
                            left: "2px",
                          }}
                        >
                          <img
                            alt=""
                            src={chevron}
                            sx={{
                              transform: state?.expanded
                                ? "rotate(90deg)"
                                : "rotate(-90deg)",
                            }}
                            width="18"
                            height="36"
                          />
                        </span>
                      )}
                    </AccordionItemState>
                    <Text type="h3" sx={{ pl: 30, userSelect: "none" }}>
                      {item.title}
                    </Text>
                  </Flex>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div
                  sx={{
                    px: 30,
                    h4: {
                      ":first-child": {
                        pt: 2,
                      },
                      pt: 4,
                      mb: 2,
                      fontSize: 18,
                      fontWeight: 500,
                      fontFamily: "heading",
                      color: "text",
                    },
                    "p:last-child": {
                      mb: 4,
                    },
                    p: {
                      mt: 2,
                    },
                  }}
                >
                  {item.content}
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          )
        )}
    </RAAcordion>
  )
}
Accordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string,
      content: PropTypes.node,
    })
  ),
}
export default Accordion
