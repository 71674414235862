/** @jsx jsx */
import { jsx } from "theme-ui"
import { useLocation } from "@reach/router"
import React from "react"
import ReactDOM from "react-dom"

import Text from "./text"
import { usePrevious } from "react-use"
import { motion, AnimatePresence } from "framer-motion"
import { useIsClient } from "../hooks/use-is-client"

const Modal = ({ title, children, onClose, visible, ...restProps }) => {
  const location = useLocation()
  const previousLocation = usePrevious(location)
  const isClient = useIsClient()
  React.useEffect(() => {
    if (previousLocation && previousLocation !== location) onClose()
  }, [location, previousLocation, onClose])
  return (
    isClient &&
    ReactDOM.createPortal(
      <AnimatePresence>
        {visible && (
          <motion.div
            key={1}
            sx={{
              position: "fixed",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              p: [0, 0, 0, 5],
              background: "rgba(0,0,0,0.5)",
              zIndex: 9999,
            }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
          >
            <motion.div
              sx={{
                width: "100%",
                height: "100%",
                px: [0, 0, 0, "30px"],
                pt: "20px",
                pb: 6,
                background: "#fff",
                boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.43)",
              }}
              exit={{ transform: "scale(0.8)" }}
              initial={{ transform: "scale(0.8)" }}
              animate={{ transform: "scale(1)" }}
              transition={{ duration: 0.4 }}
              {...restProps}
            >
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                  mx: ["20px", "20px", "20px", 0],
                }}
              >
                <Text type="h3">{title || ""}</Text>
                <Text
                  type="h3"
                  sx={{ color: "primary", cursor: "pointer" }}
                  onClick={onClose}
                >
                  &#10005;
                </Text>
              </div>

              <div sx={{ width: "100%", height: "100%", overflow: "auto" }}>
                {children}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>,
      document.body
    )
  )
}

export default Modal
