/** @jsx jsx */
import { jsx } from "theme-ui"
import Text from "../text"
import RichText from "../rich-text"
import { hasClass } from "../../helpers"

const BasicCard = ({ content, hideImage, ...restProps }) => {
  const titleType =
    content.patternType === "Blade" || hasClass(content, "title-h2")
      ? "h2"
      : "h3"
  return (
    <div sx={{ color: "inherit" }} {...restProps}>
      {content.title && (
        <Text type={titleType} sx={{ color: "inherit", mb: "18px" }}>
          {content.title}
        </Text>
      )}
      {content.body && (
        <RichText data={content.body} sx={{ color: "inherit" }} />
      )}
      {!hideImage && content.desktopImage && (
        <img alt="" src={content.desktopImage.file.url} />
      )}
    </div>
  )
}
export default BasicCard
