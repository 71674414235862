/** @jsx jsx */
import { jsx } from "theme-ui"
import Container from "./container"
import { useStaticQuery, graphql } from "gatsby"
import ContentCard from "./content-card"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulSite {
        footerItems {
          ...ContentCardData
        }
      }
    }
  `)
  return (
    <footer
      sx={{
        background: theme => theme.colors.backgrounds.primary,
      }}
    >
      <Container>
        <div>
          {data.contentfulSite.footerItems.map((card, index) => (
            <ContentCard key={index} card={card} sx={{ mr: [null, null, 4] }} />
          ))}
        </div>
      </Container>
    </footer>
  )
}

export default Footer
